var myDropzone;
var isIE = /* @cc_on!@ */ false || !!document.documentMode;
$(document).ready(function () {

    if (isIE) {
        $('html').addClass('is-ie');
    }
    if (typeof initTable === "function") {
        initTable();
    }

    if ($('.js-sx-filters').length) {
        initiateTabs();
    }
    if ($('.js-calendar').length) {
        initCalendar();
    }
    if ($('.js-table').length) {
        scrollableTable();
    }
    if ($('.ui.dropdown').length) {
        $('.ui.dropdown').dropdown();
    }
    if ($('.ui.radio.checkbox').length) {
        $('.ui.radio.checkbox').checkbox();
    }
    if ($('.tabular.menu .item').length) {
        $('.tabular.menu .item').tab();
    }
    if ($('.js-content-item').length) {
        rmBolded();
    }
    if ($('.ui.form').length) {
        $('.ui.form')
            .form({
                fields: {
                    email: {
                        identifier: 'email',
                        rules: [{
                                type: 'empty',
                                prompt: 'Моля въведете имейл'
                            },
                            {
                                type: 'email',
                                prompt: 'Въведете валиден имейл адрес'
                            }
                        ]
                    },
                    username: {
                        identifier: 'username',
                        rules: [{
                            type: 'empty',
                            prompt: 'Моля въведете ново потребителско име'
                        }]
                    },
                    password: {
                        identifier: 'password',
                        rules: [{
                                type: 'empty',
                                prompt: 'Моля въведете парола'
                            },
                            {
                                type: 'length[6]',
                                prompt: 'Вашата парола трябва да съдържа поне 6 символа'
                            }
                        ]
                    }
                }
            });
    }

    function rmBolded() {
        $('.js-content-item').on('click', function () {
            if ($('.js-content-item').hasClass('active')) {
                $(this).removeClass('bolded');
            }
        });
    }

    toggleMenu('.js-menu-toggle', '.js-menu');

    $(window).on('resize', function () {
        if ($('.js-table').length) {
            scrollableTable();
        }
        disFlexDesk('.js-menu');
        $('.main-content').removeClass('active');
        $('.site').removeClass('active');
        $('.js-menu-toggle').removeClass('active');
    });
});


$(document).on('click', '.js-mail-button, .js-content-item', function () {
    $('.js-column-one').toggleClass('active');
    $('.js-column-two').toggleClass('active');
});

$(document).on('click', '.message .close', function () {
    $(this)
        .closest('.message')
        .transition('fade');
});

$(document).on('change', ".js-loading-method", function () {
    var $this = $(this);
    $.each($('.js-hidden-fields'), function () {
        $(this).removeClass('active');
        if ($(this).data('loadingType') === $this.val()) {
            $(this).addClass('active');
        }
    });
});

function toggleMenu(menuToggle, menu) {
    $(menuToggle).on('click', function () {
        $(menu).slideToggle();
        $('.site').toggleClass('active');
        $('.js-menu-toggle').toggleClass('active');
        $('.main-content').toggleClass('active');
    });
}

function disFlexDesk(el) {
    if (window.innerWidth > 910) {
        $(el).css({
            'display': 'none'
        });
    }
}

function initiateTabs() {
    $.each($('.js-sx-filters'), function () {
        $(this).find('.item').tab();
    });
}
function initCalendar() {
    $.each($('.js-calendar'), function () {
        $(this).find('input').datepicker();
    });
}

$(document).on('click', '.sx-input', function (e) {
    e.stopPropagation();
    var $this = $(this),
        $inputFile;
    if ($(e.target).is('div')) {
        $inputFile = $this.find('input[type="file"]');
        $inputFile.trigger('click');
    }
});
$(document).on('click', '.js-file-upload-image', function () {
    $('.js-dropzone').trigger('click');
});
$(document).on('change', '.js-file-upload', function () {
    var $this = $(this),
        $parrent = $this.parents('.sx-input'),
        $val = $this[0].files[0].name;
    if ($val !== '') {
        $parrent.find('.sx-file-name').text($val);
    }
});

$(document).on('click', '.js-sx-close-tab', function () {
    var $this = $(this);
    var $thisData = $this.parents('.item').data('tab');
    $this.parents('.item').removeClass('active');
    $.each($('.sx-tab-attached'), function () {
        if ($(this).data('tab') === $thisData) {
            $(this).removeClass('active')
        }
    });
});

$('.ui.accordion').accordion({
    exclusive: false
});

// accepts jquery element which is the table parent div
function moveTableOnMouseHold($tableContainer) {
    var down = false;
    var scrollLeft = 0;
    var x = 0;
    $tableContainer.mousedown(function (e) {
        down = true;
        scrollLeft = this.scrollLeft;
        x = e.clientX;
        $('body').addClass('blockSelect');
    }).mouseup(function () {
        down = false;
        $('body').removeClass('blockSelect');
    }).mousemove(function (e) {
        if (down) {
            this.scrollLeft = scrollLeft + x - e.clientX;
            $('body').addClass('blockSelect');
        }
    }).mouseleave(function () {
        down = false;
        $('body').removeClass('blockSelect');
    });

}
$(document).on('click', '.js-show-popup-table', function () {
    var data = $(this).data('modal');
    showModal(data);
});
$(document).on('click', '.js-show-popup', function () {
    var data = $(this).data('modal');
    showModal(data);
});
$(document).on('click', '.js-close-popup', function () {
    $(this).closest('.sx-modal').modal('hide');
})


function showModal(data) {
    $.each($('.sx-modal'), function () {
        var modalData = $(this).data('modal');
        if (data == modalData) {
            if (data == 'table') {
                $(this).modal({
                    onHide: function () {
                        // tableInit.destroy();
                        // And empty the element
                    },
                    onShow: function () {
                        initTable2();
                    }
                }).modal('show');
            } else if (data == 'upload') {
                $(this).modal({
                    onHide: function () {
                        myDropzone.disable();
                        myDropzone.destroy();
                    },
                    onShow: function () {
                        myDropzone = new Dropzone("div.js-dropzone", {
                            url: "/file/post",
                            autoProcessQueue: false,
                            maxFilesize: 2,
                            createImageThumbnails: false,
                            acceptedFiles: ' .png,.gif,.jpeg,.jpg,.pdf',
                            maxFiles: 1,
                            dictDefaultMessage: dropZoneTranslations.dictDefaultMessage,
                            dictFallbackMessage: dropZoneTranslations.dictFallbackMessage,
                            dictFallbackText: dropZoneTranslations.dictFallbackText,
                            dictFileTooBig: dropZoneTranslations.dictFileTooBig,
                            dictInvalidFileType: dropZoneTranslations.dictInvalidFileType,
                            dictResponseError: dropZoneTranslations.dictResponseError,
                            dictCancelUpload: dropZoneTranslations.dictCancelUpload,
                            dictCancelUploadConfirmation: dropZoneTranslations.dictCancelUploadConfirmation,
                            dictRemoveFile: dropZoneTranslations.dictRemoveFile,
                            dictMaxFilesExceeded: dropZoneTranslations.ictMaxFilesExceeded,
                            init: function () {
                                $(this.element).parents('.field').find('.error.message').hide().html();
                                $(this.element).parents('.field').find('.js-file-upload-image input').val('');
                            }

                        });
                        // myDropzone.on('init', function (file, response) {
                        //     console.log(file,response,this,$(this));

                        // });
                        myDropzone.on('error', function (file, response) {
                            $(file.previewElement).parents('.field').find('.error.message').show().html(response);
                        });
                        myDropzone.on('addedfile', function (file) {
                            var fileName = file.name;
                            $(file.previewElement).parents('.field').find('.error.message').hide().html('');
                            $(file.previewElement).parents('.field').find('.js-file-upload-image input').val(fileName);
                            // only one file to be uploaded
                            if (this.files[1] != null) {
                                this.removeFile(this.files[0]);
                            }
                        });
                    }
                }).modal('show');
            } else {

                $(this).modal('show');
            }
        }
    });
}
// $(document).on('click', '.js-sx-remove-file',function(){
//     myDropzone.removeAllFiles();
// });
function scrollableTable() {
    $.each($('table'), function (i, o) {
        var $section = $(this).parents('.js-table-s');
        var $tableContainer = $(this).parents('.js-table-s');
        var $table = $(this);
        var tHeight = $table.height();
        var scrollWidth = $tableContainer.width();
        var tableWdith = $table.outerWidth();

        moveTableOnMouseHold($tableContainer);

        if (!$tableContainer.parents('.shadowzone').length) {
            $tableContainer.wrap('<div class="shadowzone"></div>');
            //append shadows elements
            $tableContainer.parents('.shadowzone').each(function () {
                $(this).append('<div class="shadowzone-start sr" style="height:' + tHeight + 'px"></div>');
                $(this).append('<div class="shadowzone-end sr" style="height:' + tHeight + 'px"></div>');
            });
        }

        if (tableWdith > scrollWidth) {
            $($section.closest('.shadowzone')).find('.sx-table-cont').addClass('with-shadows')
            $($section.closest('.shadowzone')).find('.shadowzone-end').removeClass('sr');
            $($section.closest('.shadowzone')).prev('.scroll-controls').removeClass('sr');
        } else {
            $($section.closest('.shadowzone')).find('.sx-table-cont').removeClass('with-shadows')
            $($section.closest('.shadowzone')).find('.shadowzone-end').addClass('sr');
            $($section.closest('.shadowzone')).prev('.scroll-controls').addClass('sr');
        }
        //on scroll show hide  shadows
        $tableContainer.on('scroll', function () {
            var $shadowCont = $(this).parents('.shadowzone');
            if (tableWdith <= scrollWidth) {
                return;
            }
            var scrollPercent = 100 * this.scrollLeft / (this.scrollWidth - this.clientWidth);
            if (scrollPercent <= 0) {
                $shadowCont.find('.shadowzone-start').addClass('sr');
                $shadowCont.find('.shadowzone-end').removeClass('sr');

                $shadowCont.prev().find('.js-scroll-left').addClass('disabled');
                $shadowCont.prev().find('.js-scroll-right').removeClass('disabled');
            } else {
                $shadowCont.find('.shadowzone-start').removeClass('sr');
                $shadowCont.find('.shadowzone-end').removeClass('sr');

                $shadowCont.prev().find('.js-scroll-left').removeClass('disabled');
                $shadowCont.prev().find('.js-scroll-right').removeClass('disabled');
            }
            if (scrollPercent >= 100) {
                $shadowCont.find('.shadowzone-end').addClass('sr');
                $shadowCont.prev().find('.js-scroll-left').removeClass('disabled');
                $shadowCont.prev().find('.js-scroll-right').addClass('disabled');
            }
        });
    });
};